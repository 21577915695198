var storage = {
    getStorage: function(){
        if(typeof(localStorage.getItem('session')) !== "undefined" && localStorage.getItem('session') !== null && localStorage.getItem('session')) {
            return JSON.parse(localStorage.getItem('session'));
        }else {
            return {};
        }
    },
    setItem: function(key, value){
        var storageSession = storage.getStorage();
        storageSession[key] = value;
        localStorage.setItem('session', JSON.stringify(storageSession));
    },
    getItem: function(key){
        var storageSession = storage.getStorage();
        if(typeof(storageSession[key]) !== "undefined" && storageSession[key] !== null && storageSession[key] !== "") {
            return storageSession[key];
        }else {
            return "";
        }
    },
    clearStorage: function(){
        localStorage.clear();
    }
};