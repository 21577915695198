$(function(){
    $("#toolbox_button").click(function(e){
        e.preventDefault();

        var toolboxContent = '<a href="#" class="noajax" id="toolbox_icon_overview"><img src="img/toolbox/toolbox_icon_overview.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_notes"><img src="img/toolbox/toolbox_icon_notes.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_draw"><img src="img/toolbox/toolbox_icon_draw.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_addcontainer"><img src="img/toolbox/toolbox_icon_addcontainer.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_showcontainer"><img src="img/toolbox/toolbox_icon_showcontainer.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_cards"><img src="img/toolbox/toolbox_icon_cards.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_examples"><img src="img/toolbox/toolbox_icon_examples.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_erase"><img src="img/toolbox/toolbox_icon_erase.svg" alt="" class="icon" /></a>';
        if(isCustomPresentation()){
            toolboxContent = '<a href="#" class="noajax" id="toolbox_icon_overview"><img src="img/toolbox/toolbox_icon_overview.svg" alt="" class="icon" /></a><a href="#" class="noajax" id="toolbox_icon_notes"><img src="img/toolbox/toolbox_icon_notes.svg" alt="" class="icon" /></a>';
        }

        $.slidePanel.show({
            content: toolboxContent
        }, {
            direction: "bottom",
            dragTolerance: 30,
            afterShow: function(){
                $(".reveal").on("click.toolbox", function(e){
                    if($(e.target).closest(".slidePanel").length === 0) {
                        $.slidePanel.hide();
                    }
                });
            },
            afterHide: function(){
                $(".reveal").off("click.toolbox");
            },
            afterLoad: function(){
                $(".slidePanel").find(".icon").click(function(e){
                    $.slidePanel.hide();
                });

                $("#toolbox_icon_overview").click(function(e){
                    e.preventDefault();
                    Reveal.toggleOverview();
                });

                $("#toolbox_icon_notes").click(function(e){
                    e.preventDefault();
                    notes.showNotesOverlay();
                });

                $("#toolbox_icon_draw").click(function(e){
                    e.preventDefault();
                    var currentSlide = $(Reveal.getCurrentSlide());

                    if(drawing.drawingIsActive){
                        drawing.stopDrawing();
                        drawing.saveDrawing(currentSlide.attr("id"))
                    }else {
                        drawing.startDrawing();
                    }
                });

                $("#toolbox_icon_examples").click(function(e){
                    e.preventDefault();
                    examples.showExamplesOverlay();
                });

                $("#toolbox_icon_addcontainer").click(function(e){
                    e.preventDefault();
                    container.addPageToContainer();
                });

                $("#toolbox_icon_showcontainer").click(function(e){
                    e.preventDefault();
                    container.showContainerOverlay();
                });

                $("#toolbox_icon_cards").click(function(e){
                    e.preventDefault();
                    cards.showCardsOverlay();
                });

                $("#toolbox_icon_erase").click(function(e){
                    e.preventDefault();

                    storage.clearStorage();
                    examples.showSelectedExamples();
                    drawing.clearDrawing();

                    showMessage("Der Präsentations-Speicher wurde erfolgreich gelöscht", true);
                });
            }
        });
    });
});