var overlay = {
    showOverlay: function(className, title, content, buttons){
        $(".overlay").remove();

        var overlayHtml = '<div class="overlay visible '+className+'" data-prevent-swipe="1">';
        if(title !== ''){
            overlayHtml += '<div id="overlaytitle">'+title+'</div>';
        }

        overlayHtml += '<div class="content">' + content + '</div>';

        if(typeof(buttons) !== "undefined"){
            overlayHtml += '<div class="buttons">' + buttons + '</div>';
        }

        overlayHtml += '</div>';

        $(".reveal").prepend(overlayHtml);

        $(".closeoverlay").click(function(e){
            e.preventDefault();
            overlay.closeOverlay();
        });

        $("#pagetitle").hide();
    },

    closeOverlay: function(){
        $(".overlay").remove();
        $("#pagetitle").show();
    }
};