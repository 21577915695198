var container = {
    addPageToContainer: function(slideId){
        if(typeof(slideId) === "undefined"){
            slideId = $(Reveal.getCurrentSlide()).attr("id");
        }

        var storageContainer = storage.getItem("container");
        if(storageContainer === "") storageContainer = {};

        if(typeof(storageContainer[slideId]) === "undefined"){
            storageContainer[slideId] = getPageTitleFromSlideId(slideId);
        }
        storage.setItem("container", storageContainer);

        showMessage('Die Seite wurde erfolgreich zum Container hinzugefügt.');
    },

    removePageFromContainer: function(slideId){
        if(typeof(slideId) === "undefined"){
            slideId = $(Reveal.getCurrentSlide()).attr("id");
        }

        var storageContainer = storage.getItem("container");
        if(storageContainer !== "") {
            if(typeof(storageContainer[slideId]) !== "undefined"){
                delete(storageContainer[slideId]);
            }
            storage.setItem("container", storageContainer);
        }
    },

    showContainerOverlay: function(){
        overlay.showOverlay('container', 'Präsentationscontainer', container.getContainerOverlayHtml(), '<a href="#" class="imageicon close closeoverlay"></a>');

        $(".delete_slide_entry").click(function(e){
            e.preventDefault();
            container.removePageFromContainer($(this).data("slideid"));
            container.showContainerOverlay();
        });

        $("#containerform").submit(function(e){
            e.preventDefault();

            if(!navigator.onLine) {
                showMessage("Internetverbindung erforderlich.");
            }else {
                var container_email = $("#container_email");
                if (container_email.val()) {
                    showMessage('Die Daten werden übertragen - bitte warten...', false);

                    var params = {
                        "action": "saveContainer",
                        "recipient": container_email.val(),
                        "notes": storage.getItem("notes"),
                        "pages": container.getPagesArray(),
                        "drawings": drawing.getDrawingsArray(container.getPagesArray()),
                        "examples": examples.getExamplesFromStorage()
                    };

                    $.post(baseUrl+"scripts/AjaxHandler.php", params, function (result) {
                        if (result.success === '1') {
                            showMessage(result.message);
                        } else {
                            showMessage(result.message);
                        }
                    }, "json");
                } else {
                    showMessage("Bitte geben Sie eine Empfänger-Adresse ein.");
                }
            }
        });
    },
    getContainerOverlayHtml: function(){
        var storageContainer = storage.getItem("container");
        if(storageContainer === "" || Object.keys(storageContainer).length === 0) return 'Es befinden sich noch keine Seiten im Container';

        var html = '<img src="img/bird_white.svg" alt="" class="bird" />';

        if(storageContainer !== "" && Object.keys(storageContainer).length > 0) {
            html += '<form id="containerform" class="sendemailform">';
            html += '<input type="email" id="container_email" placeholder="E-Mail-Adresse" />';
            html += '<div class="sendbutton"><button id="send_container_button" class="imageicon send active"></button></div>';
            html += '<div class="clearer"></div>';
            html += '</form>';
        }

        html += '<ul class="linedlist">';

        var counter = 0;
        $.each(storageContainer, function(slideId, pageTitle){
            html += '<li class="slideentry">';
                html += pageTitle;
                html += ' <a href="#" class="delete_slide_entry imageicon minus active" data-slideid="'+slideId+'"></a>';
            html += '</li>';

            counter++;

            if(counter % 10 === 0){
                html += '</ul><ul class="linedlist">';
            }
        });

        html += '</ul>';

        return html;
    },

    getPagesArray: function(){
        var pages = [];

        var storageContainer = storage.getItem("container");
        if(storageContainer === "" || Object.keys(storageContainer).length === 0) return [];

        $.each(storageContainer, function(slideId){
            pages.push(slideId);
        });
        return pages;
    }
};