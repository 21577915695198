$(function(){
    $("#drag_container").draggable({
        axis: "x"
    });

    $("#features_container").find(".bubble").click(function(e){
        e.preventDefault();

        var self = $(this);

        if(!self.hasClass("active")){
            $("#features_container").find(".bubble.active").removeClass("active");
            self.addClass("active");
        }else {
            self.removeClass("active");
        }
    });
});