function initializeReveal(){
    Reveal.initialize({
        history: true,
        center: true,
        previewLinks: false,
        maxScale: 1,
        viewDistance: 2,
        width: 1366,
        height: 1024,
        //width: "100%",
        //height: "100%",
        margin: 0,
        backgroundTransition: 'fade', // none/fade/slide/convex/concave/zoom
        //controls: false
        //transition: 'slide', // none/fade/slide/convex/concave/zoom
        //progress: false,
    });
}

function setPageTitle(currentSlide){
    if(typeof(currentSlide) === "undefined"){
        currentSlide = $(Reveal.getCurrentSlide());
    }

    var pageTitle = currentSlide.data("pagetitle");
    if(typeof(pageTitle) === "undefined" || pageTitle === "" || currentSlide.data("hide-pagetitle") === 1){
        $("#pagetitle").html("");
    }else {
        $("#pagetitle").html(pageTitle);
    }
}

function showMessage(message, autoHide){
    if(typeof(autoHide) === "undefined") autoHide = true;

    $("#messagebox").remove();

    $(".reveal").append('<div id="messagebox">'+message+'</div>');

    $("#messagebox").fadeIn().click(function(e){
        e.preventDefault();
        hideMessage();
    });

    if(autoHide){
        window.setTimeout(function(){
            hideMessage();
        }, 2000);
    }
}

function getPageTitleFromSlideId(slideId) {
    var slide = $("#"+slideId);
    var pageTitle = slide.data("pagetitle");

    if(typeof(slide.data("containertitle")) !== "undefined"){
        pageTitle = slide.data("containertitle");
    }

    if(typeof(pageTitle) === "undefined" || pageTitle === ""){
        return 'Kein Titel';
    }else {
        return stripTags(pageTitle);
    }
}

function hideMessage(){
    $("#messagebox").fadeOut(function(){
        $("#messagebox").remove();
    });
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomToken(stringLength){
    if(typeof(stringLength) === "undefined") stringLength = 10;
    var stringArray = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
    var rndString = "";
    for (var i = 1; i < stringLength; i++) {
        var rndNum = Math.ceil(Math.random() * stringArray.length) - 1;
        rndString = rndString + stringArray[rndNum];
    }
    return rndString;
}

function isCustomPresentation(){
    return !!$("html").hasClass("custom");
}

function stripTags(text){
    return text.replace(/(<([^>]+)>)/ig,"");
}

function isDevPresentation(){
    if(window.location.href.search("dev") !== -1 || window.location.href.search("localhost") !== -1) return true;
    else return false;
}
