var notes = {
    showNotesOverlay: function(){
        overlay.showOverlay('notes', 'Notizen', notes.getNotesOverlayHtml(), notes.getNotesOverlayButtonsHtml());

        $("#save_notes_button").click(function(e){
            e.preventDefault();
            storage.setItem("notes", $("#notes").val());
            overlay.closeOverlay();
        });

        $("#clear_notes_button").click(function(e){
            e.preventDefault();
            $("#notes").val("");
        });
    },
    getNotesOverlayHtml: function(){
        var readonly = '';
        var notesValue = storage.getItem("notes");
        var placeholder = 'Text für Notizen ...';

        if(isCustomPresentation()){
            readonly = 'readonly';
            placeholder = 'Keine Notizen vorhanden.';

            if(typeof(customNotes) !== "undefined" && customNotes !== ''){
                notesValue = customNotes;
            }else {
                notesValue = '';
            }
        }

        return '<textarea id="notes" placeholder="'+placeholder+'" '+readonly+'>'+notesValue+'</textarea>';
    },
    getNotesOverlayButtonsHtml: function(){
        html = '<a href="#" class="imageicon close closeoverlay"></a>';
        if(!isCustomPresentation()) {
            html += '<a href="#" id="save_notes_button" class="imageicon check"></a>';
            html += '<a href="#" id="clear_notes_button" class="imageicon erase"></a>';
        }
        return html;
    }
};