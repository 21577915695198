//if(!isDevPresentation() && window.location.protocol !== "http:") {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('ServiceWorker.js').then(function (reg) {
            // Registrierung erfolgreich
            console.log('Registrierung erfolgreich. Scope ist ' + reg.scope);
        }).catch(function (error) {
            // Registrierung fehlgeschlagen
            console.log('Registrierung fehlgeschlagen mit ' + error);
        });
    }
//}


baseUrl = 'https://pr.screenbook.at/';

initializeReveal();

Reveal.addEventListener( 'slidechanged', function( event ) {
    var slide = $(event.currentSlide);
    var previousSlide = $(event.previousSlide);

    setPageTitle(slide);

    if(!isCustomPresentation()) {
        if (typeof(drawing.drawingCanvas) !== "undefined") {
            if (drawing.drawingIsActive) {
                drawing.stopDrawing();
                drawing.saveDrawing(previousSlide.attr("id"));
            }
            drawing.showDrawing(slide.attr("id"));
        }
    }else {
        drawing.showCustomDrawing(slide.attr("id"));
    }
} );

Reveal.addEventListener( 'overviewshown', function() {
    //Beim Öffnen der Übersicht die aktuelle Zeichnung speichern und dann ausblenden
    if(!isCustomPresentation()) {
        if (typeof(drawing.drawingCanvas) !== "undefined") {
            if (drawing.drawingIsActive) {
                drawing.stopDrawing();
                drawing.saveDrawing();
            }
            drawing.hideDrawing();
        }
    }

    if(navigator.onLine) {
        $(".threedframe").hide();
        $(".threedoffline").show();
        $(".threedoffline").find("p").hide();
    }
} );
Reveal.addEventListener( 'overviewhidden', function() {
    //Nach dem Schließen der Übersicht die Zeichnung der aktuellen Seite wieder anzeigen (kurzes Timeout, damit die currentSlide richtig ist
    if(!isCustomPresentation()) {
        if (typeof(drawing.drawingCanvas) !== "undefined") {
            window.setTimeout(function () {
                drawing.showDrawing();
            }, 10);
        }
    }

    if(navigator.onLine) {
        $(".threedframe").show();
        $(".threedoffline").hide();
    }
} );


$(function(){
    FastClick.attach(document.body, {});

    setPageTitle();

    if(!isCustomPresentation()) {
        if ($("#drawingCanvas").length > 0) {
            drawing.initializeDrawing();
            drawing.showDrawing();
        }
    }else {
        drawing.showCustomDrawing();
    }

    //Links manuell setzen, da auf iOS ansonsten der Webview verlassen wird.
    $("a, area").not(".noajax").click(function (e) {
        var link = $(this);
        if (link.attr("target") !== '_blank' && link.attr("href") !== '#') {
            window.location = link.attr("href");
        }
    });


    /*Mainnavi*/
    var mainnaviContainer = $("#mainnavi_container");
    var burgerbutton = $("#burgerbutton");

    burgerbutton.click(function(e){
        e.preventDefault();

        if(burgerbutton.hasClass("active")){
            burgerbutton.removeClass("active");
        }else {
            burgerbutton.addClass("active");
            mainnaviContainer.addClass("open");
        }
    });

    $("#mainnavi_close").click(function(e){
        e.preventDefault();
        mainnaviContainer.removeClass("open");
        burgerbutton.removeClass("active");
    });

    $("#mainnavi").find("a").click(function(){
        mainnaviContainer.removeClass("open");
        burgerbutton.removeClass("active");
    });

    /*
    $("#mainnavi").find("a").click(function(){
        drawing.saveDrawing();
    });
    */

    /*3D-Modeller nur online verfügbar*/
    if(!navigator.onLine){
        $(".threedframe").hide();
        $(".threedoffline").show();
    }
});
