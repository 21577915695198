var maps = {
    map: null,

    mapData: [
        ['at-vo', 6],
        ['at-tr', 1],
        ['at-sz', 10],
        ['at-oo', 8],
        ['at-no', 4],
        ['at-wi', 13],
        ['at-bu', 2],
        ['at-st', 9],
        ['at-ka', 6]
    ],

    drawMap: function () {
        if (maps.map !== null) {
            return true;
        }

        Highcharts.setOptions({
            lang: {
                resetZoom: "Zoom zurücksetzen"
            }
        });

        maps.map = Highcharts.mapChart('screenbookmap', {
            chart: {
                backgroundColor: 'transparent',
                style: {
                    fontFamily: 'proxima_nova'
                },
                map: 'countries/at/at-all'
            },
            credits: {
                enabled: false
            },
            title: null,

            colorAxis: {
                minColor: '#13aec2',
                maxColor: '#0F2F3C'
            },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: {point.value} Mio Euro',
                style: {
                    fontSize: '16px'
                }
            },

            series: [{
                data: maps.mapData,
                name: 'Random data',
                states: {
                    hover: {
                        color: '#ffffff',
                        borderColor: '#13aec2',
                        borderWidth: 3
                    }
                }
            }]
        });
    }
};

$(function () {
    if($("#screenbookmap").length > 0) {
        maps.drawMap();
    }
});

Reveal.addEventListener('maps', function () {
    maps.drawMap();
}, false);