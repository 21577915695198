var examples = {
    showExamplesOverlay: function(){
        overlay.showOverlay('examples', 'Anwendungsbeispiele', examples.getExamplesOverlayHtml(), examples.getExamplesOverlayButtonsHtml());

        $("#examples_overlay_container").find(".example").click(function(e){
            e.preventDefault();
            $(this).toggleClass("active");
        });

        $("#save_examples_button").click(function(e){
            e.preventDefault();

            var exampleItems = [];
            $("#examples_overlay_container").find(".example.active").each(function(){
                exampleItems.push($(this).data("type"));
            });

            storage.setItem("examples", exampleItems);
            overlay.closeOverlay();

            examples.showSelectedExamples();
        });
    },
    getExamplesOverlayHtml: function(){
        var exampleItems = examples.getExamplesFromStorage();

        var html = '<div id="examples_overlay_container">';

        html += '<div class="example '+(($.inArray("privacy", exampleItems) !== -1) ? "active" : "")+'" data-type="privacy"><div class="bluebox">Datenschutz</div></div>';
        html += '<div class="example '+(($.inArray("security", exampleItems) !== -1) ? "active" : "")+'" data-type="security"><div class="bluebox">Sicherheits-<br />Handbuch</div></div>';
        html += '<div class="example '+(($.inArray("employees", exampleItems) !== -1) ? "active" : "")+'" data-type="employees"><div class="bluebox">Mitarbeiter-<br />Handbuch</div></div>';
        html += '<div class="example '+(($.inArray("marketing", exampleItems) !== -1) ? "active" : "")+'" data-type="marketing"><div class="bluebox">Vertrieb</div></div>';

        html += '</div>';
        return html;
    },
    getExamplesOverlayButtonsHtml: function(){
        html = '<a href="#" class="imageicon close closeoverlay"></a>';
        html += '<a href="#" id="save_examples_button" class="imageicon check"></a>';
        return html;
    },
    getExamplesFromStorage: function(){
        var exampleItems = '';

        if(isCustomPresentation()){
            if(typeof(customExamples) !== "undefined" && customExamples !== '' && customExamples.length > 0){
                exampleItems = customExamples;
            }
        }else {
            exampleItems = storage.getItem("examples");
        }
        if (exampleItems === "" || exampleItems.length === 0) exampleItems = ["privacy", "security", "employees", "marketing"];
        return exampleItems;
    },
    showSelectedExamples: function(){
        var exampleItems = examples.getExamplesFromStorage();

        $("#examples_container").find(".box").each(function(){
            var self = $(this);

            if($.inArray(self.data("type"), exampleItems) !== -1){
                self.addClass("visible");
            }else {
                self.removeClass("visible");
            }
        });
    }
};

$(function(){
    examples.showSelectedExamples();
});