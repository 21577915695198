var drawing = {
    signaturePad: null,
    drawingIsActive: false,
    drawingCanvas: undefined,
    activePenColor: "#0f2f3c",
    activePenWidth: 6,

    initializeDrawing: function(){
        var canvas = document.getElementById("drawingCanvas");
        drawing.drawingCanvas = $(canvas);
        drawing.resizeCanvas();

        drawing.signaturePad = new SignaturePad(canvas, {
            minWidth: 2,
            maxWidth: 4,
            penColor: drawing.activePenColor
        });

        drawing.signaturePad.off();

        //ColorPicker and PenWidth
        var drawingToolbox = $("#drawing_toolbox");
        if(drawingToolbox.length > 0) {
            drawingToolbox.find(".colorbox").click(function(e) {
                e.preventDefault();
                drawingToolbox.find(".colorbox").removeClass("active");
                $(this).addClass("active");
                drawing.setPenColor($(this).data("color"));
            });

            drawingToolbox.find(".widthbox").click(function(e) {
                e.preventDefault();
                drawingToolbox.find(".widthbox").removeClass("active");
                $(this).addClass("active");
                drawing.setPenWidth($(this).data("width"));
            });

            drawing.setPenColor(drawing.activePenColor);
            drawing.setPenWidth(drawing.activePenWidth);
        }
    },

    startDrawing: function(){
        //Swipe-Navigation und Pointer-Events am Canvas deaktivieren
        drawing.drawingCanvas.attr("data-prevent-swipe", "1");
        drawing.drawingCanvas.css("pointer-events", "auto");
        drawing.drawingCanvas.show();

        //Zeichnen-Funktion einschalten und Marker auf true setzen
        drawing.signaturePad.on();
        drawing.penColor = drawing.activePenColor;
        drawing.penWidth = drawing.activePenWidth;
        drawing.drawingIsActive = true;

        //Drawing Toolbox zeigen
        $("#drawing_toolbox").show();
    },

    stopDrawing: function(){
        //Swiper-Navigation und Pointer-Events am Canvas aktivieren
        drawing.drawingCanvas.removeAttr("data-prevent-swipe");
        drawing.drawingCanvas.css("pointer-events", "none");

        //Zeichen-Funktion ausschalten und Marker auf false setzen
        drawing.signaturePad.off();
        drawing.drawingIsActive = false;

        //Drawing Toolbox verbergen
        $("#drawing_toolbox").hide();
    },

    saveDrawing: function(slideId){
        if(typeof(slideId) === "undefined"){
            slideId = $(Reveal.getCurrentSlide()).attr("id");
        }

        //Zeichen-Daten der Seite in LocalStorage speichern
        var drawingData = storage.getItem('drawing');
        if(!drawing.signaturePad.isEmpty()) {
            if (drawingData === "") drawingData = {};
            drawingData[slideId] = drawing.signaturePad.toDataURL();
            storage.setItem('drawing', drawingData);
        }else {
            //Wenn der canvas leer ist, dann den Eintrag aus dem Storage entfernen (falls vorhanden)
            if(typeof(drawingData[slideId]) !== "undefined"){
                delete(drawingData[slideId]);
                storage.setItem('drawing', drawingData);
            }
        }
    },

    hideDrawing: function(){
        drawing.drawingCanvas.hide();
    },

    clearDrawing: function(){
        drawing.signaturePad.clear();
    },

    showDrawing: function(slideId){
        if(typeof(slideId) === "undefined"){
            slideId = $(Reveal.getCurrentSlide()).attr("id");
        }

        drawing.hideDrawing();
        drawing.clearDrawing();

        var drawingStorage = storage.getItem('drawing');

        if(!Reveal.isOverview() && typeof(drawingStorage[slideId]) !== "undefined" && drawingStorage[slideId] !== ""){
            drawing.signaturePad.fromDataURL(drawingStorage[slideId]);
            window.setTimeout(function(){
                drawing.drawingCanvas.fadeIn();
            }, 400);
        }
    },

    resizeCanvas: function(){
        var canvas = document.getElementById("drawingCanvas");

        //Wir speichern Bilder um die Hälfte verkleinert ab, statt doppelter Größe (retina).
        //Dateigröße wird sonst viel zu groß
        var ratio =  Math.max((window.devicePixelRatio) || 1, 1);
        ratio = ratio / 3;
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);

        if(drawing.signaturePad !== null) {
            drawing.showDrawing();
        }
    },

    getDrawingsArray: function(slideIds){
        var drawings = {};

        var drawingStorage = storage.getItem('drawing');

        $.each(slideIds, function(key, slideId){
            if(typeof(drawingStorage[slideId]) !== "undefined" && drawingStorage[slideId] !== ""){
                drawings[slideId] = drawingStorage[slideId];
            }
        });
        return drawings;
    },

    showCustomDrawing: function(slideId){
        if(typeof(slideId) === "undefined"){
            slideId = $(Reveal.getCurrentSlide()).attr("id");
        }

        drawing.hideCustomDrawing();

        var drawingSlide = $("#drawing_"+slideId);
        if(typeof(drawingSlide) !== "undefined"){
            drawingSlide.fadeIn();
        }
    },
    hideCustomDrawing: function(){
        $("#drawings_container").find(".drawing").hide();
    },

    setPenColor: function(penColor){
        drawing.activePenColor = penColor;

        if(drawing.signaturePad !== null) {
            drawing.signaturePad.penColor = penColor;
        }
    },

    setPenWidth: function(penWidth){
        drawing.activePenWidth = penWidth;

        if(drawing.signaturePad !== null) {
            drawing.signaturePad.minWidth = penWidth;
            drawing.signaturePad.maxWidth = penWidth + 2;
        }
    }
};

if(document.getElementById("drawingCanvas") !== null) {
    window.addEventListener("resize", drawing.resizeCanvas);
}

$(function(){
    $("#clear_drawing_button").click(function(e){
        e.preventDefault();
        drawing.clearDrawing();
        drawing.saveDrawing();
    });

    $("#stop_drawing_button").click(function(e){
        e.preventDefault();
        drawing.stopDrawing();
        drawing.saveDrawing()
    });
});