var cards = {
    cards: [
        {
            "title": "feilmayr@screenbook.at",
            "filename": "gerhard_feilmayr.vcf"
        },
        {
            "title": "yanni@screenbook.at",
            "filename": "ashraf_yanni.vcf"
        },
        {
            "title": "brunnthaler@screenbook.at",
            "filename": "david_brunnthaler.vcf"
        },
        {
            "title": "katzinger@screenbook.at",
            "filename": "christian_katzinger.vcf"
        },
        {
            "title": "szostak@screenbook.at",
            "filename": "marcin_szostak.vcf"
        }
    ],

    showCardsOverlay: function(){
        overlay.showOverlay('cards', 'Visitenkarte', cards.getCardsOverlayHtml(), '<a href="#" class="imageicon close closeoverlay"></a>');

        $(".slideentry").click(function(e){
            e.preventDefault();

            var icon = $(this).find(".activate_card");

            if(icon.hasClass("active")){
                icon.removeClass("active");
                icon.addClass("notactive");
                icon.removeClass("minus");
                icon.addClass("plus");
            }else {
                icon.removeClass("notactive");
                icon.addClass("active");
                icon.addClass("minus");
                icon.removeClass("plus");
            }
        });

        $("#cardform").submit(function(e){
            e.preventDefault();

            if(!navigator.onLine) {
                showMessage("Internetverbindung erforderlich.");
            }else {
                var cards = [];
                $(".linedlist .imageicon.active").each(function () {
                    cards.push({"title": $(this).data("title"), "filename": $(this).data("filename")});
                });

                if (cards.length > 0) {
                    var card_email = $("#card_email");
                    if (card_email.val()) {
                        showMessage('Die Daten werden übertragen - bitte warten...', false);

                        var params = {
                            "action": "sendCards",
                            "recipient": card_email.val(),
                            "cards": cards
                        };

                        $.post(baseUrl+"scripts/AjaxHandler.php", params, function (result) {
                            if (result.success === '1') {
                                showMessage(result.message);
                            } else {
                                showMessage(result.message);
                            }
                        }, "json");
                    } else {
                        showMessage("Bitte geben Sie eine Empfänger-Adresse ein.");
                    }
                } else {
                    showMessage("Bitte wählen Sie mind. eine Visitenkarte aus.");
                }
            }
        });
    },

    getCardsOverlayHtml: function(){
        var html = '<img src="img/bird_white.svg" alt="" class="bird" />';

        html += '<form id="cardform" class="sendemailform">';
            html += '<input type="email" id="card_email" placeholder="E-Mail-Adresse" />';
            html += '<div class="sendbutton"><button id="send_card_button" class="imageicon send active"></button></div>';
            html += '<div class="clearer"></div>';
        html += '</form>';

        html += '<ul class="linedlist">';

        var counter = 0;
        $.each(cards.cards, function(key, card){
            html += '<li class="slideentry">';
            html += card.title;
            html += ' <a href="#" class="activate_card imageicon minus active" data-title="'+card.title+'" data-filename="'+card.filename+'"></a>';
            html += '</li>';

            counter++;

            if(counter % 7 === 0){
                html += '</ul><ul class="linedlist">';
            }
        });

        html += '</ul>';

        return html;
    }
};