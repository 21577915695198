var charts = {
    chart: null,

    chartType: 'column',
    chartData: null,

    chartDataBar: [{
        name: 'Jane',
        data: [1, 3, 6, 4]
    }, {
        name: 'John',
        data: [5, 7, 3, 6]
    }],

    chartDataPie:[{
        name: 'gegessen',
        colorByPoint: true,
        data: [
            {
                name: 'Äpfel',
                y: 6,
                sliced: true,
                selected: true
            },
            {
                name: 'Bananen',
                y: 10
            },
            {
                name: 'Orangen',
                y: 9
            },
            {
                name: 'Birnen',
                y: 10
            }
        ]
    }],

    drawChart: function () {
        if (charts.chart !== null) {
            charts.chart.destroy();
        }
        if(charts.chartData === null){
            charts.chartData = charts.chartDataBar;
        }

        charts.chart = Highcharts.chart('screenbookchart', {
            chart: {
                backgroundColor: 'transparent',
                type: charts.chartType,
                style: {
                    fontFamily: 'proxima_nova'
                }
            },
            credits: {
                enabled: false
            },
            title: null,
            colors: ['#14afc3', '#0f323c', '#1e91a5', '#72cfdb'],
            xAxis: {
                categories: ['Äpfel', 'Bananen', 'Orangen', 'Birnen'],
                labels: {
                    style: {
                        fontSize: '17px',
                        color: '#0f2f3c'
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Gegessene Früchte',
                    style: {
                        fontSize: '17px',
                        color: '#0f2f3c'
                    }
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size: 16px">{point.key}</span><br/>.',
                style: {
                    fontSize: '14px'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            color: '#0f2f3c',
                            textOutline: 'none'
                        }
                    },
                    showInLegend: true,
                    slicedOffset: 20
                }
            },
            legend: {
                itemStyle: {
                    fontSize: '16px',
                    color: '#0f2f3c'
                }
            },
            series: charts.chartData
        });
    }
};

$(function () {
    if($("#screenbookchart").length > 0) {
        charts.drawChart();

        $("#icon_settings").click(function (e) {
            e.preventDefault();
            $("#chartsettings_container").toggleClass("open");
            $(this).toggleClass("active");
        });
        $(".icon_charttype").click(function (e) {
            e.preventDefault();
            var self = $(this);

            $(".icon_charttype.active").removeClass("active");
            self.addClass("active");

            charts.chartType = self.data("type");

            if (self.data("type") === "pie") {
                charts.chartData = charts.chartDataPie;
            } else {
                charts.chartData = charts.chartDataBar;
            }

            charts.drawChart();
            $("#chartsettings_container").removeClass("open");
        });
    }
});

Reveal.addEventListener('charts', function () {
    charts.drawChart();
}, false);